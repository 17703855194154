<template>
  <v-container>
    <template v-if="row.action === 'delete_market_detail'">
      <BaseDeletion
        id="market-rates-list-detail"
        :close="close"
        :collection-name="'market'"
        :row="row"
        :action="action"
      />
    </template>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import markets from '@/services/markets'

export default {
  components: {
    BaseDeletion,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
      action: (params) => markets.delete(params),
    }
  },
  methods: {
    close() {
      this.props.expanded = false
    },
  },
}
</script>
