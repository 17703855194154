<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Market Rates</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import MarketRatesListDetail from '@/components/MarketRatesListDetail.vue'
import markets from '@/services/markets'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'marketRate',
  ['view-market-rates', 'edit-market-rates', 'delete-market'],
  'market-rates'
)

const columns = [
  {
    _t_id: '8cca2ba1',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '4489b13f',
    prop: 'marketName',
    text: 'Name',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'marketName',
    defaultSort: true,
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Market Rates',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        enableStatusFilterToggle: false,
        enableExport: false,
        detailKeyId: 'marketId',
        tableId: 'market_tv_view',
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'market-rates.add' }),
        detail: MarketRatesListDetail,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Markets',
        action: (params) => markets.tableView(params),
      },
    }
  },
}
</script>
